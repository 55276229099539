import {
  AlertReserveSellCellDefinition,
  CellType,
  IconCellDefinition,
  PdfPurchaseCellDefinition,
  PdfResponsabilityCellDefinition,
  PdfSaleCellDefinition,
  PdfWarrantyCellDefinition,
  PhotoCellDefinition,
} from '@/components/dataTables/cell'

export const IconHeader: IconCellDefinition = {
  align: 'center',
  width: 40,
  filterable: false,
  type: CellType.icon,
}

export const PhotoHeader: PhotoCellDefinition = {
  align: 'center',
  width: 100,
  filterable: false,
  type: CellType.photo,
}

export const AlertReserveSellCellHeader: AlertReserveSellCellDefinition = {
  type: CellType.alertReserveSell,
  align: 'center',
  width: 60,
  sortable: false,
  filterable: false,
}

export const PdfWarrantyHeader: PdfWarrantyCellDefinition = {
  align: 'center',
  width: 100,
  filterable: false,
  sortable: false,
  type: CellType.pdfWarranty,
  options: {
    tooltip: 'Abrir garantia de postventa',
    disabled: true,
    action: {
      color: 'blue',
      icon: 'mdi-shield-star-outline',
      only: true,
    },
  },
}

export const PdfSaleHeader: PdfSaleCellDefinition = {
  align: 'center',
  width: 100,
  filterable: false,
  sortable: false,
  type: CellType.pdfSale,
  options: {
    tooltip: 'Abrir nota de venta',
    disabled: true,
    action: {
      color: 'blue',
      icon: 'mdi-file-document-arrow-right-outline',
      only: true,
    },
  },
}

export const PdfResponsabilityHeader: PdfResponsabilityCellDefinition = {
  align: 'center',
  width: 100,
  filterable: false,
  sortable: false,
  type: CellType.pdfResponsability,
  options: {
    tooltip: 'Abrir carta de responsabilidad',
    disabled: true,
    action: {
      color: 'blue',
      icon: 'mdi-clipboard-account-outline',
      only: true,
    },
  },
}

export const PdfPurchaseHeader: PdfPurchaseCellDefinition = {
  align: 'center',
  width: 100,
  filterable: false,
  sortable: false,
  type: CellType.pdfPurchase,
  options: {
    tooltip: 'Abrir nota de compra',
    disabled: true,
    action: {
      color: 'blue',
      icon: 'mdi-file-document-check-outline',
      only: true,
    },
  },
}
