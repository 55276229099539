import { ResourceHeaders } from '..'
import {
  CreateLinkedRecordHeader,
  DateHeader,
  FinancialHeader,
  IconHeader,
  IdHeader,
  LetterApprovalHeader,
  PriceHeader,
  TextHeader,
  VehicleHeader,
  VehicleStatusHeader,
  WhatsappHeader,
} from '@/views/datatables/resources/headers'
import { Helpers } from '@/views/datatables/resources/helpers'

import { financingStatusIcons } from '@/models/financing/interfaces'
import { Person } from '@/entities/persons'

export const headers: ResourceHeaders = [
  { ...IdHeader, text: 'Nº', value: '@' },
  { ...IconHeader, text: 'Estado negocio', value: 'financing.saleOrder.deal.status', sortable: false, width: 130 },
  { ...TextHeader, text: 'Financiera', value: 'agreement.person.alias', width: 120 },
  {
    ...TextHeader,
    text: 'Solicitante',
    value: 'financing.applicant.applicantWithRut',
    width: 220,
  },
  { ...DateHeader, text: 'Fecha de solicitud ', value: 'created' },
  {
    ...FinancialHeader,
    text: 'Estado',
    value: '@',
    options: {
      allowCreate: true,
      set: financingStatusIcons,
      showDetails: false,
    },
  },
  { ...PriceHeader, text: 'Monto', value: 'financing.startingAmount', width: 160 },
  { ...TextHeader, text: 'Pie ', value: 'pie' },
  { ...VehicleHeader, text: 'Vehiculo', value: 'financing.saleOrder.deal.auto' },
  { ...VehicleStatusHeader, text: 'Estado de Stock', value: 'financing.saleOrder.deal.stock' },
  {
    ...WhatsappHeader,
    text: 'Ejecutivo',
    value: 'financing.executive',
    width: 250,
    options: {
      action: {
        color: 'blue',
        always: true,
        icon: 'mdi-whatsapp',
        trigger: (item: Person) => Helpers.contact(item, 'Financial'),
      },
      disabledMissingProperty: ['phone'],
    },
  },
  {
    ...CreateLinkedRecordHeader,
    text: 'Cierre',
    value: 'buttonClose',
    options: {
      tooltip: 'Cierre de evaluación',
      action: {
        color: 'purple',
        icon: '$charity',
        only: true,
        trigger: item => Helpers.linkedModel(item),
      },
    },
  },
  {
    ...LetterApprovalHeader,
    text: 'Carta de aprobación',
    value: '@',
    width: 160,
  },
]

export default function (): ResourceHeaders {
  return headers
}
