import { RoleResources } from '..'

import leads from './leads'
import appraisal from './appraisal'
import inspection from './inspection'
import negotiation from './negotiation'
import purchase from './purchase'
import priceList from './priceList'
import financing from './financing'
import reservations from './reservations'
import sales from './sales'
import stock from './stock'
import consignment from './consignment'

export const executive: RoleResources = {
  staff_leads: leads,
  staff_appraisals: appraisal,
  staff_inspections: inspection,
  staff_negotiations: negotiation,
  staff_purchases: purchase,
  staff_price_list: priceList,
  staff_financings: financing,
  staff_reservations: reservations,
  staff_sales: sales,
  staff_stock: stock,
  staff_consignments: consignment,
}
