import { RoleResources } from '..'

import purchase from './purchase'
import sales from './sales'
import transfer from './transfer'
import reservations from './reservations'
import stock from './stock'

export const validator: RoleResources = {
  validator_purchases: purchase,
  validator_sales: sales,
  validator_transfer: transfer,
  validator_reservations: reservations,
  validator_stock: stock,
}
